import React, {useState, useMemo} from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {Modal, Container, Form, Row, Col, Button, Accordion} from "react-bootstrap";
import { TextField } from "@mui/material";
import { useUser} from "../contexts/user";
import Autocomplete from "@mui/material/Autocomplete";
import "../css/poi.css";
import * as AddSchemaForm from "../utils/AddSchemaForms";
import { schemaTable } from "../utils/SchemaTable";
import { createSatRow } from "../utils/Api";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

const EDITABLE_SCHEMAS = Object.keys(schemaTable);

const ModalSchemaEntry = ({
    show,
    setShow,
    sat,
    onAddSchema,
    scheme = "",
    schemeData = null,
    readonly = false
}) => {
    const { username } = useUser();
    const [saving, setSaving] = useState(false);
    const [saveStatus, setSaveStatus] = useState(<></>);
    const [wezFormData, setWezFormData] = useState(null);
    const [wezType, setWezType] = useState("");

    // Determine mode based on scheme and schemeData
    const mode = useMemo(() => {
        if (!scheme && !schemeData) {return "full_edit";}     // Full edit mode
        if (scheme && !schemeData) {return "edit_value";}     // Edit value mode
        if (schemeData) {return "edit_source";}               // Edit source mode
        return "full_edit";                                 // Default to full edit
    }, [scheme, schemeData]);

    // Only sort schemas if in full edit mode
    const sortedSchemas = useMemo(() => {
        if (mode === "full_edit") {
            return [...EDITABLE_SCHEMAS].sort((a, b) => a.localeCompare(b));
        }
        return [];
    }, [mode]);

    const [selectedScheme, setSelectedScheme] = useState(
        mode === "full_edit" ? sortedSchemas[0] : scheme
    );

    const darkTheme = createTheme({
        palette: {
            mode: "dark"
        }
    });

    const handleClose = () => setShow(false);

    const handleChange = (_, newValue) => {
        setSelectedScheme(newValue);
    };

    const handleWezValue = (data) => {
        setWezFormData(data);
    };

    const handleWezType = (type) => {
        setWezType(type);
    };

    const isValidUrl = (urlString) => {
        try {
            const url = new URL(urlString);
            return url.protocol === "http:" || url.protocol === "https:";
        } catch {
            return false;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const source = data.get("source");

        if (!isValidUrl(source)) {
            setSaveStatus(<div style={{color:"red"}}>Please enter a valid URL starting with http:// or https://</div>);
            return;
        }

        let thisScheme;
        let value;
        let schemaDetails;

        if(selectedScheme === "WEZ" || scheme === "WEZ") {
            thisScheme = wezType;
            value = JSON.stringify(wezFormData);
            schemaDetails = {
                section: schemaTable.WEZ.section
            };
        } else {
            thisScheme = mode === "full_edit" ? selectedScheme : scheme;
            value = readonly ? schemeData?.Value : data.get("value");
            schemaDetails = schemaTable[thisScheme];
        }

        if(!thisScheme || !source || !value) {return;}

        const section = schemaDetails?.section || "";

        setSaving(true);
        setSaveStatus(<div style={{color:"lightblue"}}>saving...</div>);

        const newSchema = {
            SatNo: JSON.stringify(sat.SatNo),
            Scheme: thisScheme,
            Section: section,
            Source: source,
            Value: value,
            VerifiedBy: username,
            Timestamp: null
        };

        try {
            const response = await createSatRow(newSchema);
            if (response.success === false) {
                const errorMessage = response.error || "Unknown error occurred";
                setSaveStatus(<div style={{color:"red"}}>Error: {errorMessage}</div>);
                toast.error(`Failed to save schema: ${errorMessage}`);
                setSaving(false);
                return;
            }

            newSchema["Id"] = Date.now();
            newSchema["UpdatedAt"] = new Date().toISOString();
            setSaveStatus(<></>);
            setSaving(false);
            onAddSchema(newSchema);
            setShow(false);
        } catch (error) {
            const errorMessage = error.message || "Unknown error occurred";
            toast.error(`Failed to save schema: ${errorMessage}`);
            setSaveStatus(<div style={{color:"red"}}>Error: {errorMessage}</div>);
            setSaving(false);
        }
    };

    const renderFormContent = () => {
        const currentScheme = scheme || selectedScheme;
        const schemaInfo = schemaTable[currentScheme];

        // Special form handlers for non-edit_source mode
        if (mode !== "edit_source") {
            if (currentScheme === "WEZ") {
                return AddSchemaForm.wezForm(handleWezValue, handleWezType);
            }

            if (currentScheme === "Status Description") {
                return AddSchemaForm.statusForm();
            }

            if (currentScheme === "Military Usage") {
                return AddSchemaForm.isMilitaryForm();
            }

            if (currentScheme === "Launch Date" || currentScheme === "Decay Date") {
                return AddSchemaForm.dateForm();
            }
        }

        // For both edit_source and regular text forms
        if (schemaInfo) {
            return (
                <>
                    <div><em>{schemaInfo.description}</em></div>
                    <TextField
                        className="mb-2"
                        style={{width:"100%"}}
                        name="value"
                        label={currentScheme}
                        //placeholder={mode !== 'edit_source' ? schemaInfo.description : ""}
                        defaultValue={schemeData?.Value || ""}
                        multiline
                        minRows={schemaInfo.rows}
                        InputProps={{
                            readOnly: mode === "edit_source"
                        }}
                        required
                    />
                </>
            );
        }

        // Default TextField for schemes not in schema table
        return (
            <TextField
                className="mb-2"
                style={{width:"100%"}}
                name="value"
                label={currentScheme}
                placeholder= {mode !== "edit_source" ? "Enter value for this schema": ""}
                defaultValue={schemeData?.Value || ""}
                multiline
                minRows={3}
                InputProps={{
                    readOnly: mode === "edit_source"
                }}
                required
            />
        );
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <Modal
                className="addSchemaModal"
                show={show}
                onHide={handleClose}
                style={{ background: "#000000c7" }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {mode === "edit_source"
                            ? "Add Additional Source"
                            : (mode === "edit_value" ? "Add New Value" : "Add Schema")}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{padding:0, overflow:"hidden"}}>
                    <Container>
                        <Row className="pt-4">
                            <Col>
                                <Form id="source" onSubmit={handleSubmit}>
                                    {/* Scheme Selection/Display */}
                                    <Form.Group className="mb-3" controlId="scheme">
                                        {mode === "full_edit" ? (
                                            <Autocomplete
                                                className="mb-2"
                                                disablePortal
                                                value={selectedScheme}
                                                onChange={handleChange}
                                                options={sortedSchemas}
                                                ListboxProps={{
                                                    style: {
                                                        maxHeight: "200px"
                                                    }
                                                }}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        label="Schema Type"
                                                        name="schema"
                                                        required
                                                    />
                                                }
                                            />
                                        ) : (
                                            <TextField
                                                className="mb-2"
                                                style={{width:"100%"}}
                                                label="Schema Type"
                                                name="schema"
                                                value={scheme !== "" ? scheme : selectedScheme.Scheme}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                                required
                                            />
                                        )}
                                    </Form.Group>

                                    {/* Data Entry Rules */}
                                    {mode === "full_edit" && (
                                        <Accordion className="mb-3">
                                            <Accordion.Header>Data Entry Rules</Accordion.Header>
                                            <Accordion.Body>
                                                <div>Only one URL per entry. If you have multiple sources for the same schema, please create a new entry with the same value and different source URL.</div>
                                                <div style={{color:"red", paddingTop:15}}>**Be aware of required units,data types, and formats</div>
                                                <div style={{paddingTop: 15}}>If a schema you need does not exist, use the &quot;Create New Schema&quot; button on the main Edit Modal.</div>
                                            </Accordion.Body>
                                        </Accordion>
                                    )}

                                    {/* Source URL Input */}
                                    <Form.Group className="mb-3" controlId="source">
                                        <TextField
                                            className="mb-2"
                                            style={{width:"100%"}}
                                            label="Source URL"
                                            name="source"
                                            type="url"
                                            placeholder="URL of where you found this information"
                                            defaultValue={""}
                                            multiline
                                            required
                                            inputProps={{
                                                pattern: "https?://.+",
                                                title: "Please enter a valid URL starting with http:// or https://"
                                            }}
                                        />
                                    </Form.Group>

                                    {/* Dynamic Form Content */}
                                    <Form.Group className="mb-3" controlId="value">
                                        {renderFormContent()}
                                    </Form.Group>

                                    {/* Submit Button */}
                                    <Button
                                        variant="warning"
                                        type="submit"
                                        disabled={saving}
                                        className="mb-3"
                                    >
                                        {saving ? "Saving..." : "Submit"}
                                    </Button>
                                    {saveStatus}
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </ThemeProvider>
    );
};

ModalSchemaEntry.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    sat: PropTypes.object.isRequired,
    onAddSchema: PropTypes.func.isRequired,
    scheme: PropTypes.string,
    schemeData: PropTypes.object,
    readonly: PropTypes.bool
};

export default ModalSchemaEntry;
