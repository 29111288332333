import { Accordion, Card, Row, Col, ButtonGroup, Dropdown, Image, Button } from "react-bootstrap";
import AccordionToggle from "../../utils/AccordionToggle";
import { useAccordion } from "../../contexts/AccordionContext";
import { getScheme, sortSchema } from "../../utils/SchemaUtils";
import { sectionStyles } from "../../styles/EditDataStyles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { LuChevronUp, LuTrash2, LuPlus } from "react-icons/lu";

const ImageSection = ({schema, ignoreSource, handleAddSchemaClick}) => {
    const { expandedItem, setExpandedItem, isDeleting, handleDelete } = useAccordion();
    const handlePersistAccordion = (e) => {
        setExpandedItem(e);
    };
    return (<>
        <Row className="mb-4" style={sectionStyles.header.title}>
            <Col>Images</Col>
            <Col className="float-end text-end">
                <Button
                    style={{ textWrap: "nowrap" }}
                    variant="warning"
                    onClick={() => { handleAddSchemaClick(null, "image"); }}
                    data-testid="add-image-button"
                    aria-label="Add new image"
                >
                    <LuPlus /> New Image
                </Button>
            </Col>
        </Row>
        <Accordion
            className={sectionStyles.container.className}
            variant={sectionStyles.container.variant}
            activeKey={expandedItem}
            onSelect={(e) => handlePersistAccordion(e)}>
            {getScheme(schema, ["Image"])[0].Value !== "N/A" ? sortSchema(getScheme(schema, ["Image"])).map((img) =>
                <Card
                    key={img.Id + img.Value}
                    data-testid={`image-item-${img.Id}`}
                >
                    <Card.Header className="border-0 py-4">
                        <Row
                            className="align-items-center align-text-center"
                            style={{
                                fontWeight: 700
                            }}
                        >
                            <Col style={{ minWidth: "50%" }}>
                                <Row className="align-items-center align-text-center">
                                    <Col style={{ minWidth: "150px" }} className="py-2" aria-label="Image name">{img.Scheme}</Col>
                                    <Col style={{ minWidth: "80%" }}>
                                        <div style={{ maxWidth: "100%", textWrap: "nowrap" }} className="py-2" aria-label="Image URL">{img.Value
                                            ? <div className="border rounded p-1" style={{ fontWeight: 500, overflowX: "auto"}}>
                                                <Link to={img.Value} aria-label="Image link">{img.Value}</Link></div>
                                            : ""}
                                        </div>
                                        <div style={{ maxWidth: "100%", display: "inline-flex", textWrap: "nowrap" }} className="py-2"><div className="py-1 pr-1">Source:&nbsp;</div>
                                            {ignoreSource.findIndex(
                                                (s) => s.toLowerCase() === img.Source
                                            ) === -1
                                                ? <div className="border rounded p-1" style={{ fontWeight: 500, overflowX: "auto" }}>
                                                    {img.Source ? <Link to={img.Source} aria-label="Source link">{img.Source}</Link> : <span style={{color:"red"}} aria-label="Missing source warning">Missing Source</span>}</div>
                                                : <div className="p-1" style={{ fontWeight: 500, overflowX: "auto" }} aria-label="Ignored source">{img.Source}</div>}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{ minWidth: "175px" }}>
                                <Row className="align-items-center align-text-center">
                                    <Col style={{ minWidth: "55%", textWrap: "nowrap" }}>
                                        <Row>
                                            <Col style={{ minWidth: "50%", textWrap: "nowrap"  }} className="py-2"><Row><Col style={{minWidth:"88px", maxWidth:"33%"}}>Updated</Col><Col style={{ minWidth:"175px", fontWeight:"500"}} aria-label="Last updated date">{(img.UpdatedAt)}</Col></Row></Col>
                                            <Col style={{ minWidth: "175px", textWrap: "nowrap"}} className="py-2"><Row><Col style={{minWidth:"88px", maxWidth:"33%"}}>by User</Col><Col style={{ minWidth:"175px", fontWeight:"500"}} aria-label="Updated by user">{img.VerifiedBy}</Col></Row></Col>
                                        </Row>
                                    </Col>
                                    <Col style={{ textAlign: "center", maxWidth: "fit-content"}} className="px-4">
                                        <Dropdown as={ButtonGroup} align="end">
                                            <AccordionToggle
                                                className="btn btn-warning dropdown-item-toggle"
                                                eventKey={img.Value}
                                                aria-label="Preview image"
                                                data-testid={`preview-toggle-${img.Id}`}>
                                                Preview
                                            </AccordionToggle>
                                            <Dropdown.Toggle split variant="outline-secondary" aria-label="Open image actions menu"/>
                                            <Dropdown.Menu>
                                                <button className="dropdown-delete"
                                                    disabled={
                                                        ignoreSource.findIndex(
                                                            (s) => s === img.Source
                                                        ) !== -1 || isDeleting
                                                    }
                                                    onClick={() => handleDelete(img.Id)}
                                                    data-testid={`delete-image-${img.Id}`}
                                                    aria-label="Delete image">
                                                    <LuTrash2 /> Delete
                                                </button>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Accordion.Collapse eventKey={img.Value}>
                        <Card.Body>
                            <Row className="mb-3">
                                <AccordionToggle
                                    className="acc-item-toggle rounded"
                                    style={{ padding: "4px" }}
                                    eventKey={img.Value}
                                    aria-label="Collapse image preview">
                                    <LuChevronUp fontSize="24px" />
                                </AccordionToggle>
                            </Row>
                            <Row>
                                <Image src={img.Value}
                                    style={{
                                        maxHeight: "300px",
                                        objectFit: "contain"
                                    }}
                                />
                            </Row>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            )
                : <Card><Card.Header className="border-bottom-0">Placeholder Image</Card.Header></Card>}

        </Accordion>
    </>
    );
};

ImageSection.propTypes = {
    schema: PropTypes.array.isRequired,
    ignoreSource: PropTypes.array.isRequired,
    handleAddSchemaClick: PropTypes.func.isRequired
};

export default ImageSection;
