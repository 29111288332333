import { useAccordionButton } from "react-bootstrap";
import PropTypes from "prop-types";

export default function AccordionToggle({ children, eventKey, style, className }) {

    const customOnClick = useAccordionButton(eventKey);

    return (
        <button
            className={className}
            style={style}
            onClick={customOnClick}
        >
            {children}
        </button>
    );
}

AccordionToggle.propTypes = {
    children: PropTypes.node.isRequired,
    eventKey: PropTypes.string.isRequired,
    style: PropTypes.object,
    className: PropTypes.string
};
