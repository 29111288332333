import React from "react";
import { createRoot } from "react-dom/client";
import Main from "./Main";
import "./css/custom-bootstrap.scss";
import { UsernameProvider } from "./contexts/user";
import { SatelliteProvider } from "./contexts/SatelliteContext";
import { Helmet } from "react-helmet";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <>
        <Helmet>
            <meta name="description" content="PROBE: A comprehensive database of satellite information." />
        </Helmet>
        <UsernameProvider>
            <SatelliteProvider>
                <Main />
            </SatelliteProvider>
        </UsernameProvider>
    </>
);
