import React, {useRef, useState, useEffect} from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";

const OverlayElement = ({
    text,
    element,
    placement = "right",
    className = "style-source"
}) => {
    const target = useRef(null);
    const display = useRef(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = (event) => {
            if (display.current && !display.current.contains(event.target)) {
                setShow(false);
            }
        };
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [display]);

    return (
        <>
            <span ref={target}>
                <button
                    onClick={() => setShow(true)}
                    className={className}
                    style={{border: "none", background: "none", padding: 0}}
                >
                    {text}
                </button>
            </span>

            <Overlay
                target={target.current}
                show={show}
                placement={placement}
            >
                <Tooltip className='dark-overlay'>
                    <div className="text-nowrap" ref={display}>
                        {element}
                    </div>
                </Tooltip>
            </Overlay>
        </>
    );
};

OverlayElement.propTypes = {
    text: PropTypes.string.isRequired,
    element: PropTypes.node.isRequired,
    placement: PropTypes.string,
    className: PropTypes.string
};

export default OverlayElement;
