import React from "react";
import { Accordion } from "react-bootstrap";
import SchemaItem from "./SchemaItem";
import { getSection, sortSchema } from "../../utils/SchemaUtils";
import { useAccordion } from "../../contexts/AccordionContext";
import { sectionStyles } from "../../styles/EditDataStyles";
import PropTypes from "prop-types";

const SectionAccordion = ({
    sectionTitle = "",
    section = "",
    schema,
    handleAddSchemaClick,
    valueDisplayFilter = (element) => element.Value
}) => {
    const { expandedItem, setExpandedItem } = useAccordion();

    // Get all data for this section
    const sectionEntries = getSection(schema, section);

    // Get unique schemes
    const uniqueSchemes = [...new Set(sectionEntries.map((entry) => entry.Scheme))];

    // Create SchemaItems for each unique scheme
    const sectionData = uniqueSchemes.map((scheme) => {
        const schemeEntries = sectionEntries.filter((entry) => entry.Scheme === scheme);
        const sortedEntries = sortSchema(schemeEntries);

        if (sortedEntries[0]?.Value !== "N/A") {
            return (
                <SchemaItem
                    key={scheme}
                    sectionData={sortedEntries}
                    handleAddSchemaClick={handleAddSchemaClick}
                    valueDisplayFilter={valueDisplayFilter}
                />
            );
        }
        return null;
    }).filter(Boolean);

    if (sectionData.length === 0) {
        return null;
    }

    return (
        <div data-testid="section-accordion">
            {sectionTitle && <h3 style={sectionStyles.header.title} data-testid="section-title" aria-label="Section title">{sectionTitle}</h3>}
            <Accordion
                className={sectionStyles.container.className}
                style={{ ...sectionStyles.container }}
                variant={sectionStyles.container.variant}
                activeKey={expandedItem}
                onSelect={(e) => setExpandedItem(e)}
                data-testid="section-accordion-container"
                aria-label={`${section} accordion container`}
            >
                {sectionData}
            </Accordion>
        </div>
    );
};

SectionAccordion.propTypes = {
    sectionTitle: PropTypes.string,
    section: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    variant: PropTypes.string,
    schema: PropTypes.array.isRequired,
    handleAddSchemaClick: PropTypes.func.isRequired,
    valueDisplayFilter: PropTypes.func
};

export default SectionAccordion;
