import React, {useState, useEffect} from "react";
import { Button, Row, Card } from "react-bootstrap";
import {Link} from "react-router-dom";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent } from "@mui/lab";
import { timeDifference, getScheme } from "../utils/Utils";
import { useUser } from "../contexts/user";
import ModalAddPoi from "./ModalAddPoi";
import ModalEditPoi from "./ModalEditPoi";
import "../css/poi.css";
import OverlayElement from "./OverlayElement";
import PropTypes from "prop-types";
//icons
import { LiaMapMarkerAltSolid } from "react-icons/lia";
import { FiEdit } from "react-icons/fi";

const Poi = ({ sat, onSatUpdate }) => {
    const {isLoggedIn} = useUser();
    const [showEditPoi, setShowEditPoi] = useState(false);
    const [sortedPois, setSortedPois] = useState([]);
    const [showAddPoi, setShowAddPoi] = useState(false);
    const [pois, setPois] = useState([]);
    const [poi, setPoi] = useState({});

    useEffect(()=>{
        if(sat?.Data){
            const schemeData = getScheme(sat.Data, ["poi"]);
            setPois(schemeData && schemeData[0]?.Value === "N/A" ? [] : (schemeData || []));
        }
    },[sat, sat?.Data]);

    useEffect(()=>{
        if(Array.isArray(pois)){
            const sorted = pois.sort((a,b)=>new Date(b.Timestamp) - new Date(a.Timestamp));
            setSortedPois(sorted);
        }
    }, [pois]);

    const [showTitle, setShowTitle] = useState("**No events to show");

    const editData = (poi) => {
        setPoi(poi);
        setShowEditPoi(!showEditPoi);
    };

    useEffect(()=>{
        if(sortedPois.length>0){
            setShowTitle(`${sortedPois.length} event(s)`);
        }
    }, [sortedPois]);

    const getSource = (scheme) => {
        if(!scheme.Source) {
            return scheme.Value !== "N/A"
                ? <sup style={{color:"red"}} data-testid={`source-container-${scheme.Id}`}>
                    <OverlayElement
                        text={" [Source]"}
                        element={<div data-testid={`source-warning-${scheme.Id}`}>No Source Provided</div>}
                        placement="left"
                        role="tooltip"
                    />
                </sup>
                : <></>;
        }
        return  <sup data-testid={`source-container-${scheme.Id}`}>
            <OverlayElement
                text={" [Source]"}
                element={<Link
                    className="style-link"
                    to={scheme.Source}
                    data-testid={`source-link-${scheme.Id}`}
                    role="link"
                >
                    {scheme.Source}
                </Link>}
                placement="left"
                role="tooltip"
                className="style-source"
            />
        </sup>;
    };

    const [expandedStates, setExpandedStates] = useState({});

    const toggleExpand = (id) => {
        setExpandedStates((prev) => ({
            ...prev,
            [id]: !prev[id] // Toggle the expanded state for the specific poi
        }));
    };

    return (
        isLoggedIn ?
            <>
                <ModalAddPoi
                    show={showAddPoi}
                    setShow={setShowAddPoi}
                    sat={sat}
                    onUpdateSat={onSatUpdate}
                />
                <Card className='mt-3'>
                    <Card.Title style={{textAlign: "center", margin:"1rem", color:"#ffc107"}}>
                        <p>Point of Interest Timeline</p>
                        <h5 style={{color:"white"}}>{showTitle}</h5>
                    </Card.Title>
                    <Card.Body>
                        {sortedPois.length > 0
            && (<>
                <div style={{textAlign: "center", margin:"0",color: "#ffc107"}}><h5>Newest</h5></div>
                {sortedPois.map((poi) => (
                    <Timeline
                        key={Math.random().toString()}
                        className="mb-0">
                        <TimelineItem
                            style={{paddingTop:"0px"}}>
                            <TimelineOppositeContent >
                                {timeDifference(poi.Timestamp)}
                                <br/>
                                ({poi.Timestamp}Z)
                            </TimelineOppositeContent>
                            <TimelineSeparator>

                                <TimelineConnector />

                                <Button
                                    className="edit-box"
                                    onClick={() => editData(poi)}
                                    variant="link"
                                    style={{padding: 0, background: "transparent", border: "none"}}
                                    data-testid={`edit-button-${poi.Id}`}
                                    aria-label="Edit POI"
                                >
                                    <FiEdit transform="translate(0 -6)" cursor="pointer" />
                                </Button>

                                <TimelineConnector sx={{ height: "50%" }} />

                            </TimelineSeparator>
                            <TimelineContent>
                                <div
                                    className="MuiTypography-root MuiTypography-body1 MuiTimelineContent-root MuiTimelineContent-positionRight css-18y2uza-MuiTypography-root-MuiTimelineContent-root"
                                    data-testid={`poi-content-${poi.Id}`}
                                >
                                    {expandedStates[poi.Id] ? poi.Value : `${poi.Value.substring(0, 20)}${poi.Value.length > 20 ? "..." : ""}`}
                                    {poi.Value.length > 20 && (
                                        <Button
                                            onClick={() => toggleExpand(poi.Id)}
                                            variant="link"
                                            style={{ textDecoration: "none" }}
                                            data-testid={`expand-button-${poi.Id}`}
                                            aria-label={expandedStates[poi.Id] ? "Collapse POI" : "Expand POI"}
                                        >
                                            {expandedStates[poi.Id] ? "▲" : "▼"}
                                        </Button>
                                    )}
                                    {getSource(poi)}
                                </div>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>
                ))}
                <div style={{ textAlign: "center", margin: "0", color: "#ffc107" }}><h5>Oldest</h5></div>
            </>
            )}
                        <ModalEditPoi
                            key={poi.Id}
                            show={showEditPoi}
                            setShow={setShowEditPoi}
                            poi={poi}
                            onSatUpdate={onSatUpdate}
                            satNo={sat.SatNo}
                        />
                        <br></br>

                        <Row className="text-center">
                            <br></br>
                            <Button
                                style={{
                                    maxWidth:"150px",
                                    marginLeft:"auto",
                                    marginRight: "auto"
                                }}
                                variant="warning"
                                onClick={()=>setShowAddPoi(!showAddPoi)}
                                data-testid="add-poi-button"
                                aria-label="Add new POI"
                            >
                                <LiaMapMarkerAltSolid /> Add POI
                            </Button>

                        </Row>
                    </Card.Body>
                </Card>
            </>
            :
            <div
                className="text-center pt-4"
                style={{color:"red"}}
            >
            Must be logged in to view the Point of Interest timeline.
            </div>
    );
};

Poi.propTypes = {
    sat: PropTypes.shape({
        Data: PropTypes.array,
        SatNo: PropTypes.number
    }).isRequired,
    onSatUpdate: PropTypes.func.isRequired
};

export default Poi;
