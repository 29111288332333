import { Accordion, Card, Row, Col, ButtonGroup, Dropdown, Button } from "react-bootstrap";
import AccordionToggle from "../../utils/AccordionToggle";
import { Link } from "react-router-dom";
import { LuChevronUp, LuTrash2, LuPlus } from "react-icons/lu";
import PropTypes from "prop-types";
import { useAccordion } from "../../contexts/AccordionContext";

const SchemaItem = ({sectionData, handleAddSchemaClick, updateSuccess, deleteId, valueDisplayFilter}) => {
    const { isDeleting, handleDelete } = useAccordion();

    return (sectionData[0] && sectionData[0].Value !== "N/A" &&
    (<Card key={sectionData[0].Id} data-testid="schema-item">
        <Card.Header className="border-0 py-4">
            <Row
                className="align-items-center align-text-center"
                style={{
                    fontWeight: 700
                }}
            >
                <Col style={{ minWidth: "50%" }}>
                    <Row className="align-items-center align-text-center">
                        <Col style={{ minWidth: "150px" }} className="py-2" aria-label="Schema name">{sectionData[0].Scheme.replaceAll("_", "\u200b_")}</Col>
                        <Col style={{ minWidth: "80%" }}>
                            <div style={{ maxWidth: "100%" }} className="py-2" aria-label="Schema value">{sectionData[0].Value
                                ? <div className="border rounded p-1" style={{ fontWeight: 500, overflowY: "auto", maxHeight: "110px", whiteSpace:"preserve-breaks" }}>{valueDisplayFilter(sectionData[0])}</div>
                                : ""}
                            </div>
                            <div style={{ display: "inline-flex", textWrap: "nowrap", maxWidth: "100%" }} className="py-2"><div className="py-1 pr-1">Source:&nbsp;</div>
                                <div className="border rounded p-1" style={{ fontWeight: 500, overflowX: "auto" }}>{sectionData[0].Source ? <Link to={sectionData[0].Source} aria-label="Source link">{sectionData[0].Source}</Link> : <span style={{color:"red"}} aria-label="Missing source warning">Missing Source</span>}</div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col style={{ minWidth: "175px" }}>
                    <Row className="align-items-center align-text-center">
                        <Col style={{ minWidth: "55%", textWrap: "nowrap" }}>
                            <Row>
                                <Col style={{ minWidth: "50%", textWrap: "nowrap"  }} className="py-2"><Row><Col style={{minWidth:"88px", maxWidth:"33%"}}>Updated</Col><Col style={{ minWidth:"175px", fontWeight:"500"}} aria-label="Last updated date">{sectionData[0].UpdatedAt}</Col></Row></Col>
                                <Col style={{ minWidth: "175px", textWrap: "nowrap"}} className="py-2"><Row><Col style={{minWidth:"88px", maxWidth:"33%"}}>by User</Col><Col style={{ minWidth:"175px", fontWeight:"500"}} aria-label="Updated by user">{sectionData[0].VerifiedBy}</Col></Row></Col>
                            </Row>
                        </Col>
                        <Col style={{ textAlign: "center", maxWidth: "fit-content"}} className="px-4">
                            <Dropdown as={ButtonGroup} align="end" >
                                <Button
                                    style={{ textWrap: "nowrap" }}
                                    variant="warning"
                                    onClick={() => { handleAddSchemaClick(null, sectionData[0].Scheme); }}
                                    aria-label="Add new schema entry"
                                    data-testid="add-schema-button"
                                >
                                    <LuPlus />&nbsp;New
                                </Button>

                                <Dropdown.Toggle split variant="outline-secondary" className="border-secondary" aria-label="Open schema actions menu" />
                                <Dropdown.Menu aria-label="Schema actions">
                                    <Dropdown.Item
                                        onClick={() => {
                                            handleAddSchemaClick(sectionData[0], sectionData[0].Scheme);
                                        }}
                                        data-testid="add-source-button"
                                        aria-label="Add new source">
                                        <div style={{ textAlign: "center" }}><LuPlus /> Source</div>
                                    </Dropdown.Item>
                                    <button className="dropdown-delete"
                                        onClick={() => handleDelete(sectionData[0].Id)}
                                        disabled={isDeleting}
                                        data-testid="delete-schema-button"
                                        aria-label="Delete schema entry">
                                        <LuTrash2 /> Delete
                                    </button>
                                    <Dropdown.Divider />
                                    <AccordionToggle
                                        className="dropdown-item-toggle"
                                        style={{ padding: "4px 16px" }}
                                        eventKey={sectionData[0].Scheme}
                                        aria-label="Toggle sources and history view"
                                        data-testid="sources-history-toggle">
                                        Sources &&nbsp;History
                                    </AccordionToggle>
                                </Dropdown.Menu>
                            </Dropdown>
                            {deleteId === sectionData[0].Id ? updateSuccess : ""}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card.Header>
        <Accordion.Collapse eventKey={sectionData[0].Scheme}>
            <Card.Body>
                {/* Data Body */}
                <Row><AccordionToggle className="acc-item-toggle rounded" style={{ padding: "4px" }} eventKey={sectionData[0].Scheme} aria-label="Collapse sources and history"><LuChevronUp fontSize="24px" /></AccordionToggle></Row>
                <Row>
                    {/*Current sources*/}
                    <Col style={{ minWidth: "50%"}} role="region" aria-label="Current sources">
                        <Row className="border-3 mb-2 p-0"
                            style={{
                                fontWeight: 700
                            }}><div className="my-2" style={{
                                width: "100%",
                                textAlign: "center",
                                textDecoration: "underline"
                            }}>Sources</div></Row>
                        <div style={{ maxHeight: "66vh", overflowY: "auto", overflowX:"hidden"}}>
                            {sectionData.map((entry) => (
                                <Row key={entry.Id}>
                                    {entry.Value === sectionData[0].Value &&
                                        <Col className="mb-3" style={{minWidth:"175px"}}>
                                            <Row className="mb-2 align-items-center">
                                                <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>Source</Col>
                                                <Col style={{ minWidth: "90%" }}>
                                                    <div className="border rounded p-1" style={{ textWrap: "nowrap", overflowX: "auto", maxWidth: "100%" }}><Link to={entry.source} aria-label={`Source link: ${entry.Source}`}>{entry.Source ?? ""}</Link></div>
                                                </Col>
                                            </Row>
                                            <Row className="mb-2 align-items-center">
                                                <Col style={{ minWidth: "70%" }}>
                                                    <Row>
                                                        <Col style={{ minWidth: "50%" }}>
                                                            <Row>
                                                                <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>Created</Col>
                                                                <Col style={{ minWidth: "60%", textWrap: "nowrap" }} aria-label="Source creation date">{entry.UpdatedAt}</Col>
                                                            </Row>
                                                        </Col>
                                                        <Col>
                                                            <Row>
                                                                <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>by&nbsp;User</Col>
                                                                <Col style={{ minWidth: "60%", textWrap: "nowrap" }} aria-label="Source created by">{entry.VerifiedBy}</Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col style={{ textAlign: "right" }}>
                                                    <Button
                                                        className="px-4"
                                                        variant="danger"
                                                        onClick={() => handleDelete(entry.Id)}
                                                        disabled={isDeleting}
                                                        data-testid={`delete-source-${entry.Id}`}
                                                        aria-label="Delete source"
                                                    >
                                                        <LuTrash2 />
                                                    </Button>
                                                    {deleteId === entry.Id ? updateSuccess : ""}
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                </Row>
                            ))}
                        </div>
                    </Col>
                    {/*Past Entries*/}
                    <Col style={{ minWidth: "175px" }} role="region" aria-label="Entry history">
                        <Row className="mb-2 align-items-center"
                            style={{
                                fontWeight: 700
                            }}><div className="my-2" style={{
                                width: "100%",
                                textAlign: "center",
                                textDecoration: "underline"
                            }}>History</div></Row>
                        <div style={{ maxHeight: "66vh", overflowY: "auto", overflowX: "hidden" }}>
                            {sectionData.map((entry) => (
                                <Row key={entry.Id + "Right"} className="mb-3">
                                    <Col>
                                        <Row className="mb-2 align-items-center">
                                            <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>Value</Col>
                                            <Col style={{ minWidth: "90%" }}>
                                                <div className="border rounded p-1" style={{ maxHeight: "55px", overflowY: "auto", overflowX: "hidden" }} aria-label="Historical value">{entry.Value ?? ""}</div>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center">
                                            <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>Source</Col>
                                            <Col style={{ minWidth: "90%" }}>
                                                <div className="border rounded p-1 mb-2" style={{ textWrap: "nowrap", overflowX: "auto" }}><Link to={entry.source} aria-label={`Historical source link: ${entry.Source}`}>{entry.Source ?? ""}</Link></div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2 align-items-center">
                                            <Col style={{ minWidth: "70%" }}>
                                                <Row>
                                                    <Col style={{ minWidth: "50%" }}>
                                                        <Row>
                                                            <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>Created</Col>
                                                            <Col style={{ minWidth: "60%", textWrap: "nowrap" }} aria-label="Historical entry creation date">{entry.UpdatedAt}</Col>
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>by&nbsp;User</Col>
                                                            <Col style={{ minWidth: "60%", textWrap: "nowrap" }} aria-label="Historical entry created by">{entry.VerifiedBy}</Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col style={{ textAlign: "right" }}>
                                                <Button
                                                    className="px-4"
                                                    variant="danger"
                                                    onClick={() => handleDelete(entry.Id)}
                                                    disabled={isDeleting}
                                                    data-testid={`delete-history-${entry.Id}`}
                                                    aria-label="Delete historical entry"
                                                >
                                                    <LuTrash2 />
                                                </Button>
                                                {deleteId === entry.Id ? updateSuccess : ""}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Accordion.Collapse>
    </Card>)
    );
};

SchemaItem.propTypes = {
    sectionData: PropTypes.array.isRequired,
    handleAddSchemaClick: PropTypes.func.isRequired,
    updateSuccess: PropTypes.string.isRequired,
    deleteId: PropTypes.string.isRequired,
    valueDisplayFilter: PropTypes.func.isRequired
};

export default SchemaItem;
