/**
 * Will display the provided text (truncated) and if showContext is set to true will
 * display a tool tip with the full text above it.
 */
import React, {useRef, useState} from "react";
import { Overlay, Tooltip} from "react-bootstrap";
import { truncateText } from "../utils/Utils";
import PropTypes from "prop-types";

const OverlayText = ({
    text,
    maxLength
}) => {
    const target = useRef(null);
    const [show, setShow] = useState(false);
    return (
        <>
            <span
                ref={target}
                onMouseOver={() => setShow(true)}
                onMouseOut={() => setShow(false)}
                onFocus={() => setShow(true)}
                onBlur={() => setShow(false)}
            >
                {truncateText(text, maxLength)}
            </span>

            <Overlay
                target={target.current}
                show={show}
                placement="top"
            >
                <Tooltip className="dark-tooltip">
                    {text}
                </Tooltip>
            </Overlay>
        </>
    );
};

OverlayText.propTypes = {
    text: PropTypes.string.isRequired,
    maxLength: PropTypes.number.isRequired
};

export default OverlayText;
