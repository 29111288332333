import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./pages/About";
import Dossier from "./pages/Dossier";
import DossierMin from "./pages/DossierMin";
import Home from "./pages/Home";
import ModalLogin from "./components/ModalLogin";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "./contexts/user";
import PropTypes from "prop-types";


const MainContent = ({
    setShowLoginModal
}) => {
    const location = useLocation();
    const {headerToken} = useUser();
    const hideHeaderFooter = /^\/dossier-min\/\d+/.test(location.pathname) || headerToken;

    return (
        <>
            <ToastContainer />
            {!hideHeaderFooter && <Header
                setShowLoginModal={setShowLoginModal}
            />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/dossier/:id" element={<Dossier />} />
                <Route path="/dossier-min/:id" element={<DossierMin />} />
            </Routes>
            {!hideHeaderFooter && <Footer />}
        </>
    );
};

MainContent.propTypes = {
    setShowLoginModal: PropTypes.func.isRequired
};

const Main = () => {
    const [showLoginModal, setShowLoginModal] = useState(false);

    return (
        <Router>
            <MainContent setShowLoginModal={setShowLoginModal} />
            <ModalLogin
                show={showLoginModal}
                setShow={setShowLoginModal}
            />
        </Router>
    );
};

export default Main;
