import React, { createContext, useContext, useState, useEffect, useCallback, useMemo} from "react";
import * as Api from "../utils/Api";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

const SatelliteContext = createContext();

export const SatelliteProvider = ({ children }) => {
    const [satNos, setSatNos] = useState([]);
    const satDataCache = useMemo(() => ({}), []);
    const [isLoading, setIsLoading] = useState(false);

    // Fetch satellite numbers
    const fetchSatNos = async () => {
        try {
            const fetchedSatNos = await Api.getSatNos();
            setSatNos(fetchedSatNos.sort((a, b) => a - b));
        } catch (error) {
            toast.error(`Error fetching satellite numbers: ${error.message}`);
        }
    };

    // Export fetchSatData function
    useEffect(() => {
        fetchSatNos(); // Fetch satellite numbers on mount
    }, []);

    /**
     * Fetches satellite data for the given satellite numbers.
     *
     * This function checks if the requested satellite numbers are already cached.
     * If not, it fetches the data from the API and updates the cache. It also
     * handles cache size limits and can override existing cached data based on
     * the provided `override` parameter.
     *
     * @param {Array<number>} batchSatNos - An array of satellite numbers to fetch data for.
     * @param {boolean} [override=false] - A flag indicating whether to override existing cached data.
     * @returns {Promise<Object>} - A promise that resolves to an object containing the fetched or cached satellite data.
     */
    const fetchSatData = useCallback(async (batchSatNos, override = false) => {

        if (batchSatNos.length === 0) {
            setIsLoading(false);
            return {};
        }

        setIsLoading(true);

        const cachedData = {}; // Initialize an object to store cached data
        let satNosToFetch = []; // Initialize an array for satellite numbers to fetch

        // Handle cache override based on the boolean parameter
        if (override) { // If override is true, remove specified satellite data
            for (const satNo of batchSatNos) {
                delete satDataCache[satNo]; // Remove each satellite number from cache
            }
            satNosToFetch = batchSatNos;
        } else{
        // Check each satellite number against the cache individually
            for (const satNo of batchSatNos) {
                if (satDataCache[satNo]) {
                    cachedData[satNo] = satDataCache[satNo]; // Add cached data if available
                } else {
                    satNosToFetch.push(satNo); // Add to fetch list if not cached
                }
            }
        }

        // Fetch only if there are satellite numbers to fetch
        if (satNosToFetch.length > 0) {
            try {
                const response = await Api.getSatData(satNosToFetch.filter((s) => s).join(","));

                // Append the fetched data to the cache
                for (const key in response) {
                    if (Object.prototype.hasOwnProperty.call(response, key)) {
                        satDataCache[key] = response[key];
                        cachedData[key] = response[key];
                    }
                }

                setIsLoading(false); // Stop loading after fetching
                return cachedData; // Return the newly fetched data
            } catch (error) {
                toast.error(`Error fetching satellite data: ${error.message}`);
                setIsLoading(false); // Stop loading on error
                throw error; // Propagate error to be handled by top-level component
            }
        }

        setIsLoading(false); // Stop loading if no data to fetch
        return cachedData; // Return cached data if available
    }, [satDataCache]);

    return (
        <SatelliteContext.Provider value={{ satNos, isLoading, fetchSatData, satDataCache }}>
            {children}
        </SatelliteContext.Provider>
    );
};

SatelliteProvider.propTypes = {
    children: PropTypes.node.isRequired
};

// Custom hook to use the SatelliteContext
export const useSatellite = () => {
    return useContext(SatelliteContext);
};
