import React, { useState, lazy, Suspense } from "react";
import { Card, Button, Image, Figure, FigureImage, FigureCaption } from "react-bootstrap";
import "../css/cards.css";
import OverlayText from "./OverlayText";
import { getFlagLink, getImages } from "../utils/Utils";
import { LuClipboardCopy } from "react-icons/lu";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { imgPlaceholderPath } from "../utils/constants";

// Import the Carousel component lazily
const LazyCarousel = lazy(() => import("react-responsive-carousel").then((module) => ({
    default: module.Carousel
})));

const SatelliteCardImage = ({
    sat
}) => {
    const [showImage, setShowImage] = useState(true);

    const handleCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            alert(`Link Copied:\n ${text}`);
        } catch (error) {
            alert("Failed to Copy Link.");
            toast.error("Failed to copy link");
        }
    };

    const hideImage = () => {
        setShowImage(false);
    };



    return (
        <>
            <Card
                style={{
                    backgroundColor: "#181b1f",
                    color: "white",
                    borderRadius: "0",
                    border: "0"
                }}
            >

                <Suspense fallback={<div className="loading-placeholder">Loading images...</div>}>
                    <LazyCarousel
                        showThumbs={false}
                        showIndicators={false}
                    >
                        {(getImages(sat) || []).length === 0 ? (
                            <Figure className="mb-0">
                                <FigureImage
                                    className="mb-0"
                                    style={{
                                        width: "auto",
                                        maxHeight: "89vh"
                                    }}
                                    src={imgPlaceholderPath}
                                    alt={sat?.Name}
                                />
                            </Figure>
                        ) : (
                            getImages(sat).map((images) =>
                                <Figure className="mb-0" key={images.Value}>
                                    <FigureImage
                                        className="mb-0"
                                        style={{
                                            width: "auto",
                                            maxHeight: "89vh"
                                        }}
                                        src={images.Value}
                                        alt={sat?.Name}
                                    />
                                    {images.Source &&
                                        <FigureCaption className='pb-2 mt-2 border-bottom rounded-3'>
                                            <OverlayText text={"Source: "+ images.Source} maxLength={40} />
                                            <Button
                                                className="btn-copy"
                                                title="Copy Link"
                                                aria-label="Copy Link"
                                                style={{ paddingTop: "0", paddingBottom: "0", marginLeft: "5px", marginRight: "auto" }}
                                                onClick={() => handleCopy(images.Source)}>
                                                <LuClipboardCopy />
                                            </Button>
                                        </FigureCaption>
                                    }
                                </Figure>
                            )
                        )}
                    </LazyCarousel>
                </Suspense>

                {showImage && (
                    <Image
                        style={{
                            width: "50px",
                            height: "50px",
                            position: "absolute",
                            zindex: 9999,
                            top: 0,
                            left: 5
                        }}
                        alt={sat?.CountryId}
                        src={getFlagLink(sat)}
                        onError={hideImage}
                    />
                )}

            </Card>
        </>
    );
};

SatelliteCardImage.propTypes = {
    sat: PropTypes.object.isRequired
};

export default SatelliteCardImage;
