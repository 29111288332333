import React, { useState, createContext, useContext, useEffect } from "react";
import { sendLoginRequest, sendLogoutRequest, getUser } from "../utils/Api";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UsernameContext = createContext();
export const useUser = () => useContext(UsernameContext);

export const UsernameProvider = ({ children }) => {
    const [username, setUsername] = useState("Guest");
    const [uuid, setUuid] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isTokenResolved, setIsTokenResolved] = useState(false);
    const [headerToken, setHeaderToken] = useState(false);

    const hasAccessToken = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.has("access_token");
    };

    useEffect(() => {
        if(hasAccessToken()){ // If the user has an access token in the URL, use it to log in
            const loginWithToken = async () => {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const accessToken = urlParams.get("access_token");
                const response = await sendLoginRequest({ access_token: accessToken });
                if (response.jwt) {
                    const userDetails = await getUser();
                    setUsername(userDetails.Username);
                    setUuid(response.uuid);
                    setIsLoggedIn(true);
                    setIsTokenResolved(true);
                    setHeaderToken(true);
                } else {
                    toast.error("Authentication failed: " + response.error);
                    setIsLoggedIn(false);
                    setIsTokenResolved(true);
                }
            };
            loginWithToken();
        } else if (Cookies.get("has_access_token")) { // If the user has an access token in the cookies, use it to log in
            const loginWithToken = async () => {
                const response = await sendLoginRequest();
                if (response.jwt) {
                    const userDetails = await getUser();
                    setUsername(userDetails.Username);
                    setUuid(response.uuid);
                    setIsLoggedIn(true);
                    setIsTokenResolved(true);
                } else {
                    await sendLogoutRequest();
                    setIsLoggedIn(false);
                    setIsTokenResolved(true);
                    toast.error("Cookie expired. Please log in again.");
                }
            };
            loginWithToken();
        } else { // If the user has no access token in the URL or cookies, log them out
            setIsLoggedIn(false);
            setIsTokenResolved(true);
        }
    }, []); // Empty dependency array to run only on mount

    const login = async (username, password) => {
        const response = await sendLoginRequest({ "username":username, "password":password });
        if (response.jwt) {
            setUuid(response.uuid);
            setUsername(username);
            setIsLoggedIn(true);
            setIsTokenResolved(true);
            window.location.reload();
            toast.success("Login successful!");
        } else {
            toast.error("Login failed: " + response.error);
        }
    };

    const logout = async () => {
        try {
            await sendLogoutRequest();
            setIsLoggedIn(false);
            window.location.reload();
        } catch (error) {
            toast.error("Logout failed: " + error.message);
        }
    };

    return (
        <>
            <ToastContainer />
            <UsernameContext.Provider value={{
                username,
                setUsername,
                uuid,
                isLoggedIn,
                isTokenResolved,
                login,
                headerToken,
                logout
            }}>
                {children}
            </UsernameContext.Provider>
        </>
    );
};

UsernameProvider.propTypes = {
    children: PropTypes.node.isRequired
};
