import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { deleteSatRow } from "../utils/Api";

const AccordionContext = createContext();

export const useAccordion = () => {
    const context = useContext(AccordionContext);
    if (!context) {
        throw new Error("useAccordion must be used within an AccordionProvider");
    }
    return context;
};

export const AccordionProvider = ({ children, onSatUpdate, satNo }) => {
    const [expandedItem, setExpandedItem] = useState("0");
    const [isDeleting, setIsDeleting] = useState(false);

    const handleDelete = async (id) => {
        // 1. Confirmation
        if (!window.confirm("Are you sure you want to delete this schema?\n\nNote: refresh may needed for changes to appear on dosier page.")) {
            return;
        }

        try {
            // 2. Toast - Deleting
            toast.info("Deleting schema...");
            // 3. Disable all delete buttons
            setIsDeleting(true);

            await deleteSatRow(id);

            // 4. Update parent with the affected satellite's number
            if (onSatUpdate && satNo) {
                await onSatUpdate(satNo);
            }
        } catch (error) {
            // Error Toast
            toast.error(`Error deleting schema: ${error.message}`);
        } finally {
            // 5. Re-enable delete buttons
            setIsDeleting(false);
        }
    };

    return (
        <AccordionContext.Provider value={{
            expandedItem,
            setExpandedItem,
            isDeleting,
            handleDelete
        }}>
            {children}
        </AccordionContext.Provider>
    );
};

AccordionProvider.propTypes = {
    children: PropTypes.node.isRequired,
    onSatUpdate: PropTypes.func,
    satNo: PropTypes.number
};

export default AccordionProvider;
