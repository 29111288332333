import { REGIMES } from "./constants";

export const isGeo = (sat) => {
    return (  sat?.RegimeInt === 256
        || sat?.RegimeInt === 512
        || sat?.RegimeInt === 8 );
};

/**
 * Converts the NPS sat info to a sat summary object.
 * @param {Object} npsSat, the NPS sat info
 * @returns {Object} the sat summary object
 */
const npsToSatSummary = (npsSat) => {
    return {
        Name: npsSat.Name,
        RegimeInt: npsSat.Regime,
        Regime: REGIMES[npsSat.Regime],
        Raan: parseFloat(npsSat.Raan).toFixed(4),
        Longitude: parseFloat(npsSat.Longitude).toFixed(4),
        LonDriftDegreesPerDay: parseFloat(npsSat.LonDriftDegreesPerDay).toFixed(4),
        Inclination: parseFloat(npsSat.Inclination).toFixed(4),
        Epoch: npsSat.Epoch,
        CountryId: npsSat.CountryId,
        HrrRank: npsSat.HrrRank,
        ObjectType: npsSat.ObjectType,
        LaunchDate: npsSat.LaunchDate,
        SatNo: npsSat.SatNo,
        Data: []
    };
};

/**
 * Converts the NPS schema to appropriate schema for Probe.
 * @param {Object} npsRow, the NPS schema
 * @returns {Object} the schema for Probe
 */
const npsToScheme = (npsRow) => {
    return {
        Id: npsRow.Id,
        Scheme: npsRow.Scheme,
        Section: npsRow.Section,
        Value: npsRow.Value,
        Timestamp: npsRow.Timestamp,
        Units: npsRow.Units,
        Source: npsRow.Source,
        Verified: npsRow.Verified,
        VerifiedBy: npsRow.VerifiedBy,
        Validated: npsRow.Validated,
        ValidatedSources: npsRow.ValidatedSources,
        UpdatedAt: npsRow.UpdatedAt
    };
};

const updateDataArray = (data, existingData, toDelete = false) => {
    // check if data already exists
    const existingDataIndex = existingData.findIndex((x) => x.Id === data.Id);
    if (!toDelete && existingDataIndex === -1) {
        // if data doesn't exist, add it
        return [...existingData, data];
    }
    // if data exists, replace it or delete it
    const newData = [...existingData];
    if (toDelete) {
        newData.splice(existingDataIndex, 1);
    } else {
        newData[existingDataIndex] = data;
    }
    return newData;
};

/**
 * Update/Add satellites and satellite data to the existing satellites object.
 * @param {Array} npsSatData, NPS satellite data
 * @param {Object} satellites, the existing satellites object
 * @param {Boolean} delete, if true, will delete the rows from the satellite data
 * @returns {Object} the new satellites object
 */
export const updateSatellites = (npsSatData, satellites, toDelete = false) => {
    const newSatellites = {...satellites};
    npsSatData.forEach((npsRow) => {
        if (!newSatellites[npsRow.SatNo]) {
            newSatellites[npsRow.SatNo] = npsToSatSummary(npsRow);
        }
        newSatellites[npsRow.SatNo].Data = updateDataArray(
            npsToScheme(npsRow),
            newSatellites[npsRow.SatNo].Data,
            toDelete
        );
    });
    return newSatellites;
};

export const timeDifference = (pastDateStr) => {
    const pastDate = new Date(pastDateStr);
    const currentDate = new Date();

    let yearsDifference = currentDate.getFullYear() - pastDate.getFullYear();
    let monthsDifference = currentDate.getMonth() - pastDate.getMonth();
    let daysDifference = currentDate.getDate() - pastDate.getDate();

    if (daysDifference < 0) {
        monthsDifference--;
        const previousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        daysDifference += previousMonth.getDate();
    }

    if (monthsDifference < 0) {
        yearsDifference--;
        monthsDifference += 12;
    }

    let result = "";
    if (yearsDifference > 0) {
        result += `${yearsDifference}yr `;
    }
    if (monthsDifference > 0) {
        result += `${monthsDifference}mo `;
    }
    if (daysDifference > 0) {
        result += `${daysDifference}d `;
    }

    return result ? result + "ago" : "Today";
};

export const truncateText = (text, maxLength) => {
    if(!text){return "";}
    return text.length > maxLength ? text.substring(0, maxLength) + ".." : text;
};

/**
 * Returns the URL for the flag of the given country id.
 * @param {Object} sat, the satellite object
 * @returns {String} the URL for the flag of the given country id. If no flag found, returns empty string
 */
export const getFlagLink = (sat) => {
    return (sat?.CountryId
        ? "https://purecatamphetamine.github.io/country-flag-icons/3x2/"+ sat?.CountryId+".svg"
        : "");
};

/**
 * Will search the given satellite section to find the matching data rows.
 * @param {Object} rows, The data rows of sat?.Data
 * @param {string} section, the section you want to search for.
 * @returns {Array} The data row that matches the section. If no data found, returns [{Value: null}]
 */
export const getSection = (rows, section, reduce=true) => {
    const dataRows = rows.filter((x) => x.Section === section.trim());
    if (dataRows.length > 0) {
        return !reduce ? dataRows : dataRows.reduce((acc, current) => {
            const existing = acc.find((item) => item.Scheme === current.Scheme);
            if (!existing || new Date(current.UpdatedAt) > new Date(existing.UpdatedAt)) {
                acc = acc.filter((item) => item.Scheme !== current.Scheme); // Remove old entry if it exists
                acc.push(current); // Add the more recent entry
            }

            return acc;
        }, []);
    }
    return [{Value: "N/A"}];
};

export const getDescription = (sat) => {
    if (!sat) {return "No satellite data available.";}
    return `${sat.Name} is a satellite with NORAD ID ${sat.SatNo}. It was launched on ${sat.LaunchDate}.`;
};

export const getStatus = (sat) => {
    if (!sat || !sat.Data) {return "No status available.";}
    const schemeResult = getScheme(sat.Data, ["Status Description"]);
    if (schemeResult && Array.isArray(schemeResult) && schemeResult.length > 0) {
        const value = schemeResult[0]?.Value;
        if (value && value !== "N/A") {return value;}
    }
    return "Status not specified.";
};

export const getImages = (sat) => {
    if (!sat || !sat.Data) {return [];}
    return sat.Data.filter((item) => item.Scheme === "Image");
};
/**
 * Will search the given satellite schema to find the matching scheme data row.
 * @param {Object} rows, The schema rows of sat?.Data
 * @param {Array<string>} schemesArray, An array of schemes you want to search for.
 * Note, if the first scheme can't be found, it will move on to the second and so on,
 * until data is found. Returns null if no data found
 * @returns {Array} The data row that matches the scheme. If no data found, returns [{Value: "N/A"}]
 */
export const getScheme = (rows, schemeArray) => {
    if (!rows || !Array.isArray(rows)) {return [{ Value: "N/A" }];}
    for (let i = 0; i < schemeArray.length; i++) {
        const dataRows = rows.filter((x) => x.Scheme === schemeArray[i].trim());
        if (dataRows.length > 0) {
            return dataRows;
        }
    }
    return [{ Value: "N/A" }];
};

export const getRegime = (sat) => {
    return REGIMES[sat?.Regime] || "Unknown"; // Return "Unknown" if regime is not found
};
