import { sortBy } from "lodash";

export const getScheme = (data, schemes) => {
    if (!data) {return [{ Value: "N/A" }];}
    const schemeData = Object.values(data).flat().filter((x) => schemes.includes(x.Scheme));
    return schemeData.length > 0 ? schemeData : [{ Value: "N/A" }];
};

export const getSection = (data, section, sort = true) => {
    if (!data) {return [{ Value: "N/A" }];}
    const sectionData = Object.values(data).flat().filter((x) => x.Section === section);
    if (sectionData.length === 0) {return [{ Value: "N/A" }];}
    if (!sort) {return sectionData;}
    return sortBy(sectionData, (item) => {
        if (!item || !item.UpdatedAt) {return 0;}
        return Date.parse(item.UpdatedAt);
    }).reverse();
};

export const sortSchema = (unsortSchema) => {
    return sortBy(unsortSchema, (item) => {
        if (!item || !item.UpdatedAt) {return 0;}
        return Date.parse(item.UpdatedAt);
    }).reverse();
};
