import React, { useState } from "react";
import { Navbar, Nav, Button, Image } from "react-bootstrap";
import { PersonCircle } from "react-bootstrap-icons";
import logo from "../assets/images/logo.png";
import { useUser } from "../contexts/user";
import PropTypes from "prop-types";

const Header = ({
    setShowLoginModal
}) => {
    const [isVisible] = useState(true);
    const {isLoggedIn, logout, username} = useUser();

    const logInOutClicked = async () => {
        if (isLoggedIn) {
            await logout();
        }
        else{
            setShowLoginModal(true);
        }  // Trigger login modal
    };

    return (
        <>
            { isVisible && (
                <>
                    <Navbar
                        expand="md"
                        style={{
                            paddingLeft: "20px",
                            backgroundColor: "#181b1f"
                        }}
                    >
                        <Navbar.Brand href="/">
                            <span className="yellow logo-text">
                                <Image src={logo} alt="Saber Logo"/>
                            </span>
                        </Navbar.Brand>

                        <Navbar.Toggle
                            aria-controls="basic-navbar-nav"
                            style={{ marginRight: "20px" }}
                        />

                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href="/about">About PROBE</Nav.Link>
                            </Nav>

                            <div>
                                {username}
                            </div>

                            <Button
                                variant="light"
                                style={{
                                    marginLeft: "20px",
                                    marginRight: "10px"
                                }}
                                onClick={logInOutClicked}
                            >
                                <PersonCircle /> {
                                    isLoggedIn ? "Log out" : "Log In"
                                }
                            </Button>
                        </Navbar.Collapse>
                    </Navbar>
                </>
            )}
        </>
    );
};

Header.propTypes = {
    setShowLoginModal: PropTypes.func.isRequired
};

export default Header;
