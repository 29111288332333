import React, {useState} from "react";
import { useUser } from "../contexts/user";
import { Button, Row, Col, Form, Modal, Container} from "react-bootstrap";
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";
import { LuTrash2 } from "react-icons/lu";
import { TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { deleteSatRow, updateSatRow } from "../utils/Api";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import "../css/poi.css";

const ModalEditPoi = ({
    show,
    setShow,
    satNo,
    poi,
    onSatUpdate
}) => {
    const {username} = useUser();
    const [saving, setSaving] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);

    const darkTheme = createTheme({
        palette: {
            mode: "dark"
        }
    });

    const handleClose = () => {
        setSaving(false);
        setShow(false);
        setUpdateSuccess(<></>);
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this schema?\n\nNote: refresh may needed for changes to appear on dosier page.")) {
            try {
                setUpdateSuccess(
                    <span style={{ color: "#ffc107" }}>
                        <CiCircleCheck style={{ fontSize: "1.5rem" }} /> Deleting...
                    </span>
                );
                await deleteSatRow(id);
                setUpdateSuccess(
                    <span style={{ color: "Green" }}>
                        <CiCircleCheck style={{ fontSize: "1.5rem" }} /> Deleted
                    </span>
                );
                await onSatUpdate(satNo);
                setShow(false);
            } catch (error) {
                toast.error("An error occurred while deleting the schema. Please try again.");
                setUpdateSuccess(
                    <span style={{ color: "red" }}>
                        <CiCircleRemove style={{ fontSize: "1.5rem" }} /> Error
                    </span>
                );
            }

        }
    };

    /* Updates PSRA and Timeline */
    const handleSubmit  = async (e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        const id = data.get("id");
        const timestamp = data.get("timestamp");
        const source = data.get("source");
        const value = data.get("value");

        setSaving(true);
        setUpdateSuccess(<></>);

        const updateScheme = {
            Source: source,
            Value: value,
            Timestamp: timestamp,
            VerifiedBy: username
        };
        try{
            await updateSatRow(id,updateScheme);
            setSaving(false);
            await onSatUpdate();
            handleClose();
        } catch(e){
            setSaving(false);
            setUpdateSuccess(<div style={{ color: "red" }}>Failed to update the entry. Please try again later.</div>);
            toast.error("Failed to update entry:", e);
        }

        e.target.reset();
    };

    return(
        <ThemeProvider theme={darkTheme}>
            <Modal
                className="poi-modal"
                show={show}
                onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                            Edit POI
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{padding:0, overflow:"hidden"}}>
                    <Container>
                        <Row className="pt-4">
                            <Form id={poi.Id} onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="poi">
                                    <Form.Control
                                        name="id"
                                        type="hidden"
                                        defaultValue={poi.Id}
                                        required
                                    />
                                    <TextField
                                        label="Event Timestamp"
                                        type="text"
                                        color="warning"
                                        style={{width:"100%"}}
                                        defaultValue={poi.Timestamp ?? ""}
                                        name="timestamp"
                                        placeholder="Timestamp"
                                        required
                                    />
                                    <Row><br></br></Row>

                                    <TextField
                                        label="Source (URL preferred)"
                                        color="warning"
                                        style={{width:"100%"}}
                                        defaultValue={poi.Source ?? ""}
                                        name="source"
                                        placeholder="Source (URL preferred)"
                                        required
                                    />
                                    <Row><br></br></Row>
                                    <TextField
                                        label="POI Details"
                                        color="warning"
                                        style={{width:"100%"}}
                                        defaultValue={poi.Value ?? ""}
                                        name="value"
                                        placeholder="Value"
                                        required
                                        multiline
                                    />
                                    <Row><br></br></Row>
                                    <div className="mb-2">
                                            Verified by: <b>{username}</b>
                                    </div>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <Button variant="warning"
                                                type="submit"
                                                disabled={saving}
                                            >
                                                Update
                                            </Button>
                                        </Col>
                                        <Col className="text-end float-end">
                                            <Button
                                                className="px-4"
                                                variant="danger"
                                                onClick={() => handleDelete(poi.Id)}
                                            >
                                                <LuTrash2 /> Delete
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row><Col className="text-center float-center">{updateSuccess}</Col></Row>
                                </Form.Group>
                            </Form>
                        </Row>
                    </Container>

                </Modal.Body>

            </Modal>
        </ThemeProvider>
    );
};

ModalEditPoi.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    satNo: PropTypes.string.isRequired,
    poi: PropTypes.object.isRequired,
    onSatUpdate: PropTypes.func.isRequired
};

export default ModalEditPoi;
