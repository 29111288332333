// This file will contain utility functions or components related to adding filter forms.
// You can define shared logic or components here that can be used across different form components.

import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

// Validation rules for different parameters
export const VALIDATION_RULES = {
    SatNo: {
        placeholder: "Enter satellite number",
        validate: (value) => Number.isInteger(Number(value)) && Number(value) > 0,
        errorMessage: "Must be a positive integer"
    },
    Inclination: {
        placeholder: "Enter inclination (-180 to 180)",
        validate: (value) => !isNaN(value) && Number(value) >= -180 && Number(value) <= 180,
        errorMessage: "Must be a number between -180 and 180"
    },
    Longitude: {
        placeholder: "Enter longitude (-180 to 180)",
        validate: (value) => !isNaN(value) && Number(value) >= -180 && Number(value) <= 180,
        errorMessage: "Must be a number between -180 and 180"
    },
    LonDriftDegreesPerDay: {
        placeholder: "Enter drift rate",
        validate: (value) => !isNaN(value),
        errorMessage: "Must be a number"
    },
    Raan: {
        placeholder: "Enter RAAN (0 to 360)",
        validate: (value) => !isNaN(value) && Number(value) >= 0 && Number(value) <= 360,
        errorMessage: "Must be a number between 0 and 360"
    },
    LaunchDate: {
        placeholder: "YYYY-MM-DD",
        validate: (value) => {
            const date = new Date(value);
            return !isNaN(date.getTime()) && Boolean(value.match(/^\d{4}-\d{2}-\d{2}$/));
        },
        errorMessage: "Must be a valid date in YYYY-MM-DD format"
    },
    Epoch: {
        placeholder: "YYYY-MM-DD",
        validate: (value) => {
            const date = new Date(value);
            return !isNaN(date.getTime()) && Boolean(value.match(/^\d{4}-\d{2}-\d{2}$/));
        },
        errorMessage: "Must be a valid date in YYYY-MM-DD format"
    }
};

// Reusable form for parameters that use operator and value inputs
export const OperatorValueForm = ({
    parameter,
    operator,
    setOperator,
    start,
    setStart,
    end,
    setEnd
}) => {

    const validationRule = VALIDATION_RULES[parameter];

    const validateInput = (value) => {
        if (!value) {return true;} // Allow empty values during typing
        return validationRule.validate(value);
    };

    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label>Operator</Form.Label>
                <Form.Select
                    aria-label="Operator"
                    className="mb-3"
                    value={operator}
                    onChange={(e) => setOperator(e.target.value)}
                >
                    <option>=</option>
                    <option>!=</option>
                    <option>&lt;</option>
                    <option>&gt;</option>
                    <option>&lt;=</option>
                    <option>&gt;=</option>
                    <option>range</option>
                </Form.Select>
            </Form.Group>
            {operator === "range" ? (
                <>
                    <Form.Group className="mb-3">
                        <Form.Label>Start Value</Form.Label>
                        <Form.Control
                            aria-label="Start"
                            type="text"
                            placeholder={validationRule.placeholder}
                            value={start}
                            onChange={(e) => setStart(e.target.value)}
                            isInvalid={start && !validateInput(start)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {validationRule.errorMessage}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>End Value</Form.Label>
                        <Form.Control
                            aria-label="End"
                            type="text"
                            placeholder={validationRule.placeholder}
                            value={end || ""}
                            onChange={(e) => setEnd(e.target.value)}
                            isInvalid={end && !validateInput(end)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {validationRule.errorMessage}
                        </Form.Control.Feedback>
                    </Form.Group>
                </>
            ) : (
                <Form.Group className="mb-3">
                    <Form.Label>Value</Form.Label>
                    <Form.Control
                        aria-label="Value"
                        type="text"
                        placeholder={validationRule.placeholder}
                        value={start}
                        onChange={(e) => setStart(e.target.value)}
                        isInvalid={start && !validateInput(start)}
                    />
                    <Form.Control.Feedback type="invalid">
                        {validationRule.errorMessage}
                    </Form.Control.Feedback>
                </Form.Group>
            )}
        </>
    );
};

OperatorValueForm.propTypes = {
    parameter: PropTypes.string.isRequired,
    operator: PropTypes.string.isRequired,
    setOperator: PropTypes.func.isRequired,
    start: PropTypes.string.isRequired,
    setStart: PropTypes.func.isRequired,
    end: PropTypes.string,
    setEnd: PropTypes.func.isRequired
};

// Keep existing forms for parameters with different input types
export const CountryIdForm = ({ start, setStart }) => (
    <Form.Group className="mb-3">
        <Form.Label>Country Name</Form.Label>
        <Form.Select
            aria-label="Value"
            value={start}
            onChange={(e) => setStart(e.target.value)}
        >
            <option>--</option>
            <option value={"CN"}>China</option>
            <option value={"DE"}>Germany</option>
            <option value={"IR"}>Iran</option>
            <option value={"JP"}>Japan</option>
            <option value={"PK"}>Pakistan</option>
            <option value={"RU"}>Russia</option>
            <option value={"US"}>United States</option>
        </Form.Select>
    </Form.Group>
);

CountryIdForm.propTypes = {
    start: PropTypes.string.isRequired,
    setStart: PropTypes.func.isRequired
};

export const ObjectTypeForm = ({ start, setStart }) => (
    <Form.Group className="mb-3">
        <Form.Label>Select Type</Form.Label>
        <Form.Select
            aria-label="Value"
            value={start}
            onChange={(e) => setStart(e.target.value)}
        >
            <option>--</option>
            <option value={"PAYLOAD"}>Payload</option>
            <option value={"ROCKET BODY"}>Rocket Body</option>
            <option value={"DEBRIS"}>Debris</option>
        </Form.Select>
    </Form.Group>
);

ObjectTypeForm.propTypes = {
    start: PropTypes.string.isRequired,
    setStart: PropTypes.func.isRequired
};

export const RegimeForm = ({ start, setStart }) => (
    <Form.Group className="mb-3">
        <Form.Label>Regime</Form.Label>
        <Form.Select
            aria-label="Value"
            value={start}
            onChange={(e) => setStart(e.target.value)}
        >
            <option value={"1"}>Undetermined</option>
            <option value={"2"}>Leo</option>
            <option value={"4"}>Heo</option>
            <option value={"8"}>GeoInclined</option>
            <option value={"16"}>Meo</option>
            <option value={"32"}>Molniya</option>
            <option value={"64"}>Sso</option>
            <option value={"128"}>Polar</option>
            <option value={"256"}>GeoStationary</option>
            <option value={"512"}>Graveyard</option>
        </Form.Select>
    </Form.Group>
);

RegimeForm.propTypes = {
    start: PropTypes.string.isRequired,
    setStart: PropTypes.func.isRequired
};

export const RankForm = ({ start, setStart }) => (
    <Form.Group className="mb-3">
        <Form.Label>Enter HRR Rank</Form.Label>
        <Form.Control
            type="number"
            aria-label="Value"
            min="1"
            max="100"
            value={start}
            onChange={(e) => setStart(e.target.value)}
        />
    </Form.Group>
);

RankForm.propTypes = {
    start: PropTypes.string.isRequired,
    setStart: PropTypes.func.isRequired
};

export const ParameterSelect = ({ parameter, setParameter, isLoggedIn }) => (
    <Form.Select
        aria-label="Parameter"
        value={parameter}
        onChange={(e) => setParameter(e.target.value)}
        required
    >
        <option>--</option>
        <option value={"CountryId"}>Country of Launch</option>
        <option value={"LaunchDate"}>Launch Date</option>
        <option value={"SatNo"}>Sat Number/Norad ID Range</option>
        <option>Regime</option>
        <option value={"ObjectType"}>Object Type</option>
        <option>Epoch</option>
        <option>Inclination</option>
        <option>Longitude</option>
        <option value={"LonDriftDegreesPerDay"}>Longitude Drift Degrees</option>
        <option value={"Raan"}>RAAN</option>
        {isLoggedIn && (<option value={"Rank"}>HrrRank</option>)}
    </Form.Select>
);

ParameterSelect.propTypes = {
    parameter: PropTypes.string.isRequired,
    setParameter: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired
};

// You can add more utility functions or components as needed.
